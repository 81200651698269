import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Me from './me.jpg';
import './Resume.scss';

export default function Resume() {
    return (
        <div className="cards-container">
            <div className="card">
                <div className="card-title">About Me</div>
                <div>
                    <div className="paragraph">
                        <span className="first-word">Howdy!</span> 👋 My name is
                        Jared Cheney. I'm a full-stack software engineer with
                        over 7 years experience, located among the beautiful
                        pines in East Texas. I strive to create products that
                        people will love, that are intuitive, easy and fun to
                        use.
                    </div>
                    <div className="paragraph">
                        Oustide of programming I enjoy trail running and
                        spending time with my girlfriend and dogs. I also do a
                        fair amount gaming (as you could probably tell by my
                        portfolio).
                    </div>
                    <div className="paragraph">
                        Connect with me on{' '}
                        <a
                            href="https://www.linkedin.com/in/jared-cheney/"
                            className="link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            LinkedIn
                        </a>{' '}
                        and let's build something awesome!
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-title">Work</div>
                <div className="view-work">
                    <Link to="/work-examples">
                        View Examples
                        <FontAwesomeIcon icon={faArrowRight} className="icon" />
                    </Link>
                </div>
                <div>
                    <div className="paragraph">
                        I'm well-experienced all across the stack from
                        implementing new front end designs, designing state
                        management systems, to creating new APIs. I am highly
                        proficient with many technologies including
                        React/Angular/Vue, JavaScript, HTML, CSS, Node, PHP,
                        MySQL and many others.
                    </div>
                    <div className="paragraph">
                        Check out some of the things I've worked on below, both
                        professional and personal.
                    </div>
                </div>
                <div className="card-title card-sub-title">Professional</div>
                <div className="card-sub-body">
                    <ul>
                        <li>
                            <div className="list-header">
                                Wound Care Advantage
                            </div>
                            <div className="work-detail">
                                Developed Woundle, a Wordle spin-off game made
                                specifically for wound care with dynamic word
                                length, hints and more. Check it out at{' '}
                                <a
                                    href="https://www.thewca.com/woundle"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link"
                                >
                                    thewca.com/woundle
                                </a>
                                .
                            </div>
                            <div className="work-detail">
                                Created a comprehensive product report featuring
                                over 30,000 products, each meticulously
                                categorized with diverse filters and criteria
                                that the user could control. This report enabled
                                data-driven decision-making, showcasing product
                                usage exceeding 3 million treatments.
                            </div>
                        </li>
                        <li>
                            <div className="list-header">Verse.io</div>
                            <div className="work-detail">
                                Spearheaded the complete overhaul of the Verse
                                Capture settings page in the Customer Portal,
                                leveraging Angular and SASS to implement tabbed
                                navigation and modern design.
                            </div>
                            <div className="work-detail">
                                Enhanced user experience by implementing dynamic
                                image icons for Verse Capture through fetching
                                from the public S3 bucket, integrated with the
                                capture widget built using React.
                            </div>
                            <div className="work-detail">
                                Optimized network load and improved front-end
                                data fetching/loading in the Customer Portal and
                                Concierge Console.
                            </div>
                        </li>
                        <li>
                            <div className="list-header">Seismic</div>
                            <div className="work-detail">
                                Developed and implemented various features for
                                Seismic DocCenter iOS application, utilizing
                                Swift and Objective-C.
                            </div>
                            <div className="work-detail">
                                Streamlined the user interface by replacing
                                Material-UI/custom UI components in Collections
                                and Email Web Blast with an internal UI toolkit
                                called Mantle (
                                <a
                                    href="https://mantle-ui.seismic.com/#/Data/Timestamp"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="link"
                                >
                                    mantle-ui.seismic.com
                                </a>
                                ).
                            </div>
                            <div className="work-detail">
                                Designed and created a reusable Timestamp
                                component using React within the internal UI
                                toolkit to display formatted dates based on
                                string or Date objects.
                            </div>
                        </li>
                        <li>
                            <span className="list-header">Verse.io</span>
                            <div className="work-detail">
                                Transformed the legacy Concierge Console from
                                Vue to Angular and Angular Material, while
                                replacing WebSockets with Pusher for improved
                                real-time communication.
                            </div>
                            <div className="work-detail">
                                Integrated Survey.js into the Concierge Console,
                                enabling customers to customize questions by
                                developing a CSV to JSON parser using Laravel.
                            </div>
                            <div className="work-detail">
                                Rebuilt and redesigned the customer-facing
                                Customer Portal using Angular and Angular
                                Material, enhancing usability and visual appeal.
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="card-title card-sub-title">Personal</div>
                <div className="card-sub-body">
                    <ul>
                        {/* sound cued  */}
                        <li>
                            <span className="list-header">Sound Cued</span> - A
                            browser-based multiplayer music guessing game. Uses
                            a music recognition service to create song/artist
                            choices and WebSockets for real time game updates.
                            Project is currently in development but can be found
                            at{' '}
                            <a
                                href="https://soundcued.com/"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                            >
                                soundcued.com
                            </a>
                            .
                        </li>
                        {/* lemur links  */}
                        <li>
                            <span className="list-header">Lemur Links</span> -
                            An all-in-one link management solution that allows
                            users to create, edit, and track links. Sign up for
                            free at{' '}
                            <a
                                href="https://lemurlinks.com/"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                            >
                                lemurlinks.com
                            </a>
                            .
                        </li>
                        {/* weatherfy  */}
                        <li>
                            <span className="list-header">Weatherfy</span> - A
                            web app that uses location data (auto-detected or
                            inputted) to look up the weather in the area and
                            smartly matches it to a Spotify playlist. Check it
                            out at{' '}
                            <a
                                href="https://weatherfy.net/"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                            >
                                weatherfy.net
                            </a>
                            .
                        </li>
                        {/* game stat tracker  */}
                        <li>
                            <span className="list-header">
                                Game Stat Tracker
                            </span>{' '}
                            - A React Native application that allows users to
                            look up their stats for the games they play online.
                            Available on both{' '}
                            <a
                                href="https://apps.apple.com/us/app/game-stat-tracker/id1447886739"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                            >
                                iOS{' '}
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://play.google.com/store/apps/details?id=com.tyypos.gamestats"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                            >
                                Android
                            </a>
                            .
                        </li>
                        {/* game clips  */}
                        <li>
                            <span className="list-header">Game Clips</span> - A
                            Swift application that allows users to view their
                            clips from the Xbox Network. Available exclusively
                            on{' '}
                            <a
                                href="https://apps.apple.com/us/app/game-clips/id1554746133"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                            >
                                iOS
                            </a>
                            .
                        </li>
                        {/* NgxGooglePlaceAutocomplete */}
                        <li>
                            <span className="list-header">
                                NgxGooglePlaceAutocomplete
                            </span>{' '}
                            - A simple and lightweight Angular directive for
                            google place autocomplete. Check it out on{' '}
                            <a
                                href="https://www.npmjs.com/package/ngx-google-place-autocomplete"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                            >
                                npm
                            </a>{' '}
                            or{' '}
                            <a
                                href="https://github.com/t-g-c/ngx-google-place-autocomplete/tree/master"
                                target="_blank"
                                rel="noreferrer"
                                className="link"
                            >
                                GitHub
                            </a>
                            .
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card">
                <div className="footer">
                    <div className="img-container">
                        <img src={Me} className="img" alt="Jared" />
                    </div>
                    <div className="footer-text">
                        <div>Thanks for stopping by!</div>
                        <div className="social">
                            <div>Connect with me</div>
                            <div>
                                <a
                                    href="https://www.linkedin.com/in/jared-cheney/"
                                    className="link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
